@import './base';

// Theme overrides for Bulma.
@import "~bulmaswatch/darkly/overrides";

// Bulma imports.
@import "~bulma/sass/base/all";

// Hack to keep the navbar toggle visible on devices
// that have a dark mode enabled.
.navbar-burger span {
  filter: invert(100%);
  background-color: $black !important;
}